.entire-paragraph {
    margin : 40px
}

h2, h3, h4, .text-center {
    text-align : center
}

h1 {
    cursor : pointer;
}

header {
    margin : 30px
}

.card-post {
    background-color : #E7FEE3
}

input {
    display : none;
}

.textarea {
    padding : 8px;
    resize  : vertical;
    width   : 100%;
}

.dialog_size {
    min-width : 300px;
}

.grid {
    padding-right : 20px;
}

.button-text-color {
    color : black;
}

.center-items {
    display         : flex;
    justify-content : center;
}

.custom-width {
    width : 300px;
}

.table {
    margin : auto;
    width  : 700px;
}

.header-color {
    background-color : #E1FFE1;
}