body {
    font-family             : -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing  : antialiased;
    margin                  : 0;
    -moz-osx-font-smoothing : grayscale;
}

code {
    font-family : source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}